import React from 'react'

const Footer = () => {
  const thisYear = new Date().getFullYear().toString()
  const footerText = `© 2014 - ${thisYear} VMware, Inc`
  return (
    <div className={'bg-black type-accent-gray paxxl'}>
      {footerText} <br/>
      All rights reserved <br/>

      <div className={'mtxl'}>
        <a href="https://www.broadcom.com/company/legal/terms-of-use" target='_blank' rel="noopener noreferrer">Terms of Use</a><br/>
        <a href="https://www.broadcom.com/company/legal/privacy" target='_blank' rel="noopener noreferrer">Privacy Policy</a><br/>
        <a href="https://www.broadcom.com/support" target='_blank' rel="noopener noreferrer">Support and Services</a>
      </div>
    </div>
  )
}

export default Footer
