import React, { ChangeEvent, ReactElement } from 'react'
import { FlexCol, Grid } from 'pivotal-ui/react/flex-grids'
import { Dispatch } from 'redux'
import { InfoAlert } from 'pivotal-ui/react/alerts'
import { Select } from 'pivotal-ui/react/select'
import { connect } from 'react-redux'
import {
  opsManagerMinorTargetVersionChanged,
  OpsManagerMinorTargetVersionChangedAction,
  updateOpsManagerMinorTargetVersion,
  UpdateOpsManagerMinorTargetVersionAction
} from '../actions/select_ops_manager_minor_target_version'
import AnalyticsWrapper from '../services/analytics_wrapper'
import { UpgradePortalStore } from '../stores/store'
import { OpsManagerVersion, toOpsManagerVersion, versionName } from '../repositories/foundation'
import { hideRecommendationBubble, MinorRecommendation } from '../actions/diagnostic_report_recommendation'
import { recalculateCompatibility, RecalculateCompatibilityAction } from '../actions/recalculate_compatibility'
import { ChosenProduct } from '../chosen_products/chosen_products_types'
import { Semver } from '../services/semver'
import { ProductRelease } from '../repositories/products'

type OpsManagerTargetVersionSelectorProps = {
  opsManagerMinorVersion: OpsManagerVersion
  analyticsWrapper: AnalyticsWrapper
  diagnosticReportRecommendation: MinorRecommendation
  recommendedOpsmanVersion: OpsManagerVersion
  chosenProducts: ChosenProduct[]
  updateOpsManagerMinorTargetVersion: (version: OpsManagerVersion) => UpdateOpsManagerMinorTargetVersionAction
  opsManagerMinorTargetVersionChanged: () => OpsManagerMinorTargetVersionChangedAction
  recalculateCompatibility: (_: boolean) => RecalculateCompatibilityAction
  dispatch: Dispatch
}

export function OpsManagerTargetVersionSelector({opsManagerMinorVersion, analyticsWrapper, updateOpsManagerMinorTargetVersion, opsManagerMinorTargetVersionChanged, diagnosticReportRecommendation, recommendedOpsmanVersion, chosenProducts, dispatch}: OpsManagerTargetVersionSelectorProps) {
  const onSelectVersion = (selection: OpsManagerVersion) => {
    return () => {
      analyticsWrapper.fireEvent('Dropdown', 'Click', 'N-Selector')
      dispatch(hideRecommendationBubble())
      dispatch(updateOpsManagerMinorTargetVersion(toOpsManagerVersion(selection)))
      dispatch(opsManagerMinorTargetVersionChanged())
      dispatch(recalculateCompatibility(true))
    }
  }

  const onChangeDropdown = (event: ChangeEvent<HTMLInputElement>) => {
    onSelectVersion(toOpsManagerVersion(event.target.value))()
  }

  const options = Object.values(OpsManagerVersion).slice(4, -1).map((nVersion: OpsManagerVersion | string, index: number) => {
    let version = nVersion as OpsManagerVersion
    return <option value={nVersion} key={index}>{versionName(version)}</option>
  })

  let recommendationBubble = <span/>
  if (diagnosticReportRecommendation === MinorRecommendation.RECOMMENDATION_SHOW_BUBBLE) {
    recommendationBubble = <div className={'recommender-alert'}>
      <div className={'recommender-bubble'}>
        <InfoAlert withIcon dismissable onDismiss={() => dispatch(hideRecommendationBubble())}>
          We set your Upgrade Target Version
          to {versionName(opsManagerMinorVersion)} to maintain compatibility with all the tiles in the
          foundation.
        </InfoAlert>
      </div>
    </div>
  }

  let selectClassname = ''
  let selectValue = 'n/a'
  if (Object.values(OpsManagerVersion).indexOf(opsManagerMinorVersion) > 3) {
    selectClassname = 'out-of-support-opsman-selected'
    selectValue = opsManagerMinorVersion.toString()
  }

  let recommendedElement = (version: OpsManagerVersion, isOutOfSupportVersion: boolean): ReactElement => {
    let recommendedElement: ReactElement = <></>
    if (recommendedOpsmanVersion === OpsManagerVersion.NO_VERSION) return recommendedElement
    if (diagnosticReportRecommendation === MinorRecommendation.NO_RECOMMENDATION) return recommendedElement

    if ((isOutOfSupportVersion && Object.values(OpsManagerVersion).indexOf(recommendedOpsmanVersion) > 3) || recommendedOpsmanVersion === version) {
      recommendedElement = <div className={'recommended-text'}>
        Recommended
      </div>
    }
    return recommendedElement
  }

  let opsmanReleases = chosenProducts.find((chosenProduct) => chosenProduct.slug === 'ops-manager')?.releases
  let opsmanCardReleases = calculateOpsmanCardReleases(opsmanReleases)

  let versionFirstCard = Object.values(OpsManagerVersion)[0]
  let versionSecondCard = Object.values(OpsManagerVersion)[1]
  let versionThirdCard = Object.values(OpsManagerVersion)[2]
  let versionFourthCard = Object.values(OpsManagerVersion)[3]

  return <div className={'ptxxl pbxxl ops-manager-version-selector'}>
    <h2 className='inline pbl'>Select Operations Manager Target Versions</h2>
    <p className='pbl'>Target versions of platform and service tiles will be compatible with the version of Operations
      Manager selected here.</p>
    <Grid className={'pbxxl grid-nogutter'}>
      <FlexCol col={5}>
        <VersionCard
          selectCurrentVersion={onSelectVersion(versionFirstCard)}
          productRelease={opsmanCardReleases[Semver.fromString(versionFirstCard).minor]}
          opsmanMinorVersion={versionFirstCard}
          recommendedElement={recommendedElement(versionFirstCard, false)}
          isSelected={opsManagerMinorVersion === versionFirstCard}
          isLTS={true}
        />
      </FlexCol>
      <FlexCol col={5}>
        <VersionCard
          selectCurrentVersion={onSelectVersion(versionSecondCard)}
          productRelease={opsmanCardReleases[Semver.fromString(versionSecondCard).minor]}
          opsmanMinorVersion={versionSecondCard}
          recommendedElement={recommendedElement(versionSecondCard, false)}
          isSelected={opsManagerMinorVersion === versionSecondCard}
          isLTS={true}
        />
      </FlexCol>
      <FlexCol col={5}>
        <VersionCard
          selectCurrentVersion={onSelectVersion(versionThirdCard)}
          productRelease={opsmanCardReleases[Semver.fromString(versionThirdCard).minor]}
          opsmanMinorVersion={versionThirdCard}
          recommendedElement={recommendedElement(versionThirdCard, false)}
          isSelected={opsManagerMinorVersion === versionThirdCard}
          isLTS={false}
        />
      </FlexCol>
      <FlexCol col={5}>
        <VersionCard
          selectCurrentVersion={onSelectVersion(versionFourthCard)}
          productRelease={opsmanCardReleases[Semver.fromString(versionFourthCard).minor]}
          opsmanMinorVersion={versionFourthCard}
          recommendedElement={recommendedElement(versionFourthCard, false)}
          isSelected={opsManagerMinorVersion === versionFourthCard}
          isLTS={false}
        />
      </FlexCol>
      <FlexCol className={'col-3-5'}>
        <Grid className={'prior-versions'}>
          <FlexCol col={24}>
            <label htmlFor="select-ops-manager-minor-version" className="sr-only">prior versions</label>
            <Select className={selectClassname} id={'select-ops-manager-minor-version'} onChange={onChangeDropdown}
              value={selectValue}>
              <option value={'n/a'} disabled={true} aria-disabled={true}>prior versions</option>
              {options}
            </Select>
          </FlexCol>
        </Grid>
        {recommendedElement(OpsManagerVersion.NO_VERSION, true)}
      </FlexCol>
    </Grid>
    {recommendationBubble}
  </div>
}

const mapStateToProps = ({opsManagerMinorVersion, diagnosticReportRecommendation, recommendedOpsmanVersion, chosenProducts}: UpgradePortalStore) => {
  return {
    opsManagerMinorVersion,
    diagnosticReportRecommendation,
    recommendedOpsmanVersion,
    chosenProducts
  }
}

const mapDispatchToProps = (dispatch: Dispatch) => ({
  updateOpsManagerMinorTargetVersion,
  opsManagerMinorTargetVersionChanged,
  recalculateCompatibility,
  dispatch
})

export default connect(mapStateToProps, mapDispatchToProps)(OpsManagerTargetVersionSelector)


function calculateOpsmanCardReleases(opsmanReleases: ProductRelease[] | undefined) {
  let opsmanCardReleases: { [key: string]: ProductRelease } = {}
  if (opsmanReleases) {
    opsmanReleases.sort((a, b) => {
      return Semver.fromString(b.version).compare(Semver.fromString(a.version))
    }).forEach((release) => {
      let version = Semver.fromString(release.version)
      if (opsmanCardReleases[version.minor]) return

      Object.values(OpsManagerVersion).slice(0, 4).forEach((cardVersion) => {
        if (version.sameMinor(Semver.fromString(cardVersion))) {
          opsmanCardReleases[version.minor] = release
        }
      })
    })
  }
  return opsmanCardReleases
}

type VersionCardProps = {
  selectCurrentVersion: () => void
  opsmanMinorVersion: OpsManagerVersion
  recommendedElement: ReactElement
  productRelease: ProductRelease
  isSelected: boolean
  isLTS: boolean
}

export function VersionCard(
  {
    selectCurrentVersion,
    opsmanMinorVersion,
    recommendedElement,
    isSelected,
    productRelease,
    isLTS
  }: VersionCardProps) {
  let className = 'version-card'
  if (isSelected) {
    className = 'version-card-selected'
  }

  let newFeaturesUrl = `https://docs.vmware.com/en/VMware-Tanzu-Operations-Manager/${opsmanMinorVersion.split('.').slice(0, 2).join('.')}/vmware-tanzu-ops-manager/release-notes.html#major-features`

  // The next variable is needed here to ensure that we do not trigger
  // the selection of the version on the card
  let linkClicked = false
  let onNewFeaturesClick = () => {
    linkClicked = true
  }

  let splitOpsmanVersion = opsmanMinorVersion.toString().split('.')

  const selectVersion = () => {
    if (linkClicked) {
      linkClicked = false
      return
    }
    selectCurrentVersion()
  }

  let ltsBanner = null
  if (isLTS) {
    className += ' lts-version-card'
    ltsBanner = <FlexCol col={24}>
      <div className={'lts-banner'}>Long Term Support</div>
    </FlexCol>
  }

  return <div className={className} onClick={selectVersion} onKeyPress={selectVersion}
    id={`version-card-${splitOpsmanVersion[0]}-${splitOpsmanVersion[1]}`}>
    <Grid
      className={'grid-nogutter outer-card'}
      tabIndex={0}
      aria-label={`Select Operations Manager Version ${splitOpsmanVersion[0]}.${splitOpsmanVersion[1]}`}>
      {ltsBanner}
      <FlexCol col={24} className={'mlm plm'}>
        <Grid className={'grid-nogutter'}>
          <FlexCol col={10} className={'version'}>
            {versionName(opsmanMinorVersion)}
          </FlexCol>
          <FlexCol col={14}>
            <Grid className={'grid-nogutter plm'}>
              <FlexCol col={24} className={'eogs'}>
                EOGS: {productRelease?.endOfSupportDate || '-'}
              </FlexCol>
              <FlexCol col={24} className={'new-features'}>
                <a onClick={onNewFeaturesClick} href={newFeaturesUrl} target={'_blank'} rel={"noopener noreferrer"}>New Features</a>
              </FlexCol>
            </Grid>
          </FlexCol>
        </Grid>
      </FlexCol>
    </Grid>
    {recommendedElement}
  </div>
}
