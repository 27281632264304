import React from 'react'

import { ErrorAlert } from 'pivotal-ui/react/alerts'
import {
  AffectedProduct, AffectedRelease, DependentsNotSatisfiedProduct, ErrorInfoState, InconsistentStateErrorInfoState,
  PlanErrorTypes
} from '../error_info/error_info_types'

const DefaultError = () => (
  <div>
    We couldn't find an upgrade plan for you. Please double check the data you provided and
    contact <a href="https://www.broadcom.com/support" target='_blank' rel="noopener noreferrer">Broadcom
    Support</a> for further assistance.
  </div>
)

const TimeoutError = () => (
  <div>
    Upgrade plan creation timed out. Please try again.
  </div>
)

const InconsistentStateError = ({error}: { error: InconsistentStateErrorInfoState }) => (
  <div>
    {error.type === PlanErrorTypes.INCONSISTENT_STATE_CURRENT ? 'Current' : 'Target'} versions you've selected have
    dependencies that are not met, according to our metadata. Please check
    your entries and dependencies on Broadcom's Support Portal.

    {formatReleases(error.affected)}

    Please check your entries and dependencies on Broadcom's Support Portal and update your plan accordingly.
  </div>
)

const DowngradeError = ({products}: { products: DowngradedProduct[] }) => (

  <div>
    Product downgrades are not supported. Please fix the following products:
    {formatProducts(products)}
  </div>
)

const PathNotFoundError = ({products}: { products: PathNotFoundProduct[] }) => {
  let product = products[0]
  let currentVersion = product.currentVersion
  let targetVersion = product.targetVersion
  let productLink = null
  if (product.link !== undefined) {
    productLink =
      <a href={product.link} target='_blank' rel="noopener noreferrer" className='release-link'>{product.name}</a>
  }
  return (
    <div>
      We couldn't find an upgrade plan for you. The release metadata on Broadcom's Support Portal
      for {productLink === null ? product.name : productLink} suggests that
      there is no upgrade path from {currentVersion} to {targetVersion}. Please try again and if this error still
      appears, contact
      <a href="https://www.broadcom.com/support" target='_blank' rel="noopener noreferrer">Broadcom Support</a> for
      further assistance.
    </div>
  )
}

const DepNotSatisfiedError = ({product}: { product: DependentsNotSatisfiedProduct }) => {
  return (
    <div>
      We couldn't find an upgrade plan for you. The release metadata on Broadcom's Support Portal for {product.name} suggests
      that there is no upgrade path from {product.currentVersion} to {product.targetVersion} that
      maintains compatibility with other tiles. Please try again and if this error still appears,
      contact <a href="https://www.broadcom.com/support" target='_blank' rel="noopener noreferrer">Broadcom Support</a> for
      further assistance.
    </div>
  )
}

const NoValidStatesError = () => (
  <div>
    We couldn't find an upgrade plan for you. There was an issue generating upgrade phases that maintain compatibility
    for the product releases selected.
    Please check the data you entered, try again and if this error still appears
    contact <a href="https://www.broadcom.com/support" target='_blank' rel="noopener noreferrer">Broadcom Support</a> for
    further assistance.
  </div>
)

type ErrorProduct = {
  name: string
  slug: string
  link: string
}

export type DowngradedProduct = ErrorProduct

export type PathNotFoundProduct = ErrorProduct & {
  currentVersion: string
  targetVersion: string
}

const PlanStepsError = ({error}: { error: ErrorInfoState }) => {
  let errorContent
  switch (error.type) {

  case PlanErrorTypes.INCONSISTENT_STATE_CURRENT:
  case PlanErrorTypes.INCONSISTENT_STATE_TARGET:
    errorContent = <InconsistentStateError error={error}/>
    break
  case PlanErrorTypes.DEPENDENTS_NOT_SATISFIED:
    errorContent = <DepNotSatisfiedError product={error.affected}/>
    break
  case PlanErrorTypes.DOWNGRADE:
    errorContent = <DowngradeError products={error.affected}/>
    break
  case PlanErrorTypes.PATH_NOT_FOUND:
    errorContent = <PathNotFoundError products={error.affected}/>
    break
  case PlanErrorTypes.TIMEOUT:
    errorContent = <TimeoutError/>
    break
  case PlanErrorTypes.NO_VALID_STATES:
    errorContent = <NoValidStatesError/>
    break
  default:
    errorContent = <DefaultError/>
  }

  return (
    <ErrorAlert withIcon>
      {errorContent}
    </ErrorAlert>
  )
}
const formatReleases = (releases: AffectedRelease[]) => {
  return releases.map((release: AffectedRelease) => {
    let itemAfterText = null
    let dependencyLinks = null

    if (release.dependencies !== undefined) {
      dependencyLinks = <ul>{
        release.dependencies.map((dep: AffectedProduct) => {
          let versionLinks = dep.versionSpecifiers
          let versionLinkText = versionLinks.reduce((acc, curr, index, arr) => {
            acc += ', '

            if (index === arr.length - 1) {
              acc += 'or '
            }

            acc += curr

            return acc
          })

          return <li key={`${release.slug}-${dep.slug}`}>
            <a
              href={dep.link} target='_blank' rel="noopener noreferrer"
              className='release-link'>{dep.name}</a> {versionLinkText}
          </li>
        })}
      </ul>
      itemAfterText = ' requires'
    }

    let item
    if (release.link) {
      item = <a href={release.link} target="_blank" rel="noopener noreferrer" className='release-link'>
        {release.name}
      </a>
    } else {
      item = release.name
    }

    return (
      <ul key={'error-info-' + release.slug}>
        <li>
          {item}{itemAfterText}
        </li>
        {dependencyLinks}
      </ul>
    )
  })
}

const formatProducts = (products: DowngradedProduct[]) => {
  return (
    <ul>{
      products.map((product: DowngradedProduct) => {
        return (
          <li key={`${product.slug}-${product.slug}`}>
            <a href={product.link} target='_blank' rel="noopener noreferrer" className='release-link'>{product.name}</a>
          </li>
        )
      })}
    </ul>
  )
}

export default PlanStepsError
