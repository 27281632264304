import React from 'react'
import { connect } from 'react-redux'

import { LOADING_STATES } from '../reducers/upgrade_plan_loading_state'

import { FlexCol, Grid } from 'pivotal-ui/react/flex-grids'
import { UpgradePortalStore } from '../stores/store'

type PlanLoadingSpinnerProps = {
  loadingState: string
}

const Stages = () => {
  let stages = [
    {key: 'start',          text: 'Reading product information'},
    {key: 'product_info',   text: 'Validating foundation information'},
    {key: 'validation',     text: 'Getting compatibility information from Broadcom\'s Support Portal'},
    {key: 'created_states', text: 'Generating Upgrade Plan'}
  ]

  let output = stages.map((obj) => {
    const name = obj.text
    let icon = <div></div>
    let textSizeClass = 'em-low'
    textSizeClass += ' txt-l'

    return (
      <Grid key={`stage-${name}`} justifyContent={'flex-start'} className={'txt-l'}>
        <FlexCol col={2} className={'pvm'}>
          {icon}
        </FlexCol>
        <FlexCol>
          <span className={textSizeClass} style={{fontSize: '12px'}}>{name}</span>
        </FlexCol>
      </Grid>
    )
  })

  return (
    <div>
      {output}
    </div>
  )
}

const PlanLoadingSpinner = (props: PlanLoadingSpinnerProps) => {
  if (props.loadingState !== LOADING_STATES.PENDING) return null

  return (
    <div className={'loading-spinner'}>
      <div className={'overlay'}/>
      <div className={'spinner-bg'}>
        <div className={'box'}>
          <Grid>
            <FlexCol col={2} fixed/>
            <FlexCol col={22}>
              <h3 className={'txt-l'}>Generating Upgrade Plan</h3>
            </FlexCol>
            <FlexCol col={2} fixed/>
            <FlexCol col={22}>
              <Stages key={'stages'}/>
            </FlexCol>
          </Grid>
        </div>
      </div>
    </div>
  )
}

export { PlanLoadingSpinner }

export default connect(
  (state: UpgradePortalStore) => {
    return {
      loadingState: state.upgradePlanLoadingState,
    }
  }
)(PlanLoadingSpinner)