import React from 'react'
import { InfoAlert } from 'pivotal-ui/react/alerts'
import { Header } from 'pivotal-ui/react/siteframe/header'
import { FlexCol } from 'pivotal-ui/react/flex-grids'
import { BrowserRouter as Router, Link } from 'react-router-dom'
import logo from '../assets/pup_icon.png'

class UpgradePlannerHeader extends React.Component {
  render() {
    let termsOfServiceAlert = null
    let termsOfServiceAlertViewed = localStorage.getItem('terms-of-service-banner-viewed')
    if (!termsOfServiceAlertViewed) {
      termsOfServiceAlert = <InfoAlert dismissable={true}
        id={'terms-of-service-banner'}
        className={'txt-c'}
        onDismiss={() => {
          localStorage.setItem('terms-of-service-banner-viewed', 'yes')
        }}>
        By using this site, you consent to the Upgrade
        Planner <a href='https://www.broadcom.com/company/legal/terms-of-use' target='_blank' rel='noopener noreferrer'>Terms of Service</a> and
        the use of your data as described in Broadcom’s <a href='https://www.broadcom.com/company/legal/privacy' target='_blank' rel='noopener noreferrer'>Privacy Policy</a>.
      </InfoAlert>
    }

    return <div>
      {termsOfServiceAlert}
      <Header className={'bg-dark-gray pal no-margin'} companyName={''} cols={[
        <FlexCol fixed contentAlignment='middle'>
          <a href='/'>
            <img src={logo} alt='Upgrade Planner' height='38px'/>
          </a>
        </FlexCol>,
        <FlexCol className={'pln'} fixed>
          <a href='/'>
            <h1 className={'paxl type-white'}>Upgrade Planner</h1>
          </a>
        </FlexCol>,
        <FlexCol/>,
        <FlexCol fixed contentAlignment='middle'>
          <Router>
            <Link className={'header-button'} to='/docs' target="_blank">Docs</Link>
          </Router>
        </FlexCol>,
        <FlexCol fixed/>]
      }/>
    </div>
  }
}

export { UpgradePlannerHeader }
